import React, {
  useCallback,
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';
// Api
import { HOMEPAGE_SEARCH } from 'api/search/queries';
import { SEARCH_PERFORMED } from 'api/analytics/mutations';
// Types
import {
  HomepageSearch,
  HomepageSearchVariables,
} from 'api/search/types/HomepageSearch';
import { MemorabiliaProductType, UserRole } from 'api/graphql-global-types';
import { SearchBarGroupProps, SearchBarOptionProps } from '../SearchBar';
import {
  SearchLocation,
  SearchResultType,
} from 'api/graphql-analytics-global-types';
import {
  SearchPerformed,
  SearchPerformedVariables,
} from 'api/analytics/types/SearchPerformed';
import analyticsClient from 'api/analyticsClient';
// Constants
import { SOCIAL_MEDIA_POST } from 'constants/routes';
// Helpers
import { getStreamerName } from 'helpers/streams';
import { computeOrderStreamPath } from 'helpers/routes';

type SearchBarContextType = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  options: SearchBarGroupProps[];
  loading: boolean;
  setSearchFocused: (value: boolean) => void;
  setSelectedOption: (value: SearchBarOptionProps) => void;
};

const SearchBarContext = createContext<SearchBarContextType>(
  {} as SearchBarContextType
);

const SearchBarContextProvider: React.FC = ({ children }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchFocused, setSearchFocused] = useState<boolean>(false);

  // used to prevent Search Performed API call on initial mount, as a way of optimization
  // API will be called only after first click inside the search bar by checking for "isFirstSearch" and if search bar is focused
  const isFirstSearch = useRef(true);

  const [
    selectedOption,
    setSelectedOption,
  ] = useState<SearchBarOptionProps | null>(null);

  const [homepageSearch, { data, loading }] = useLazyQuery<
    HomepageSearch,
    HomepageSearchVariables
  >(HOMEPAGE_SEARCH, {
    ssr: false,
  });

  const [analyticsSearch] = useMutation<
    SearchPerformed,
    SearchPerformedVariables
  >(SEARCH_PERFORMED, {
    client: analyticsClient,
  });

  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const lastSearchTerm = useRef<string>('');
  const trimmedSearchedValue = searchValue.trim();
  const isNewSearchTerm: boolean =
    trimmedSearchedValue !== lastSearchTerm.current;

  const handleSendAnalytics = (
    id: string | null = null,
    searchType: SearchResultType | null = null
  ) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    if (trimmedSearchedValue) {
      analyticsSearch({
        variables: {
          input:
            id && searchType
              ? {
                  term: trimmedSearchedValue,
                  location: SearchLocation.Home,
                  searchResult: { srType: searchType, srId: id },
                }
              : {
                  term: trimmedSearchedValue,
                  location: SearchLocation.Home,
                },
        },
      });
      lastSearchTerm.current = trimmedSearchedValue;
    }
  };

  const initiateAnalyticsWithDebounce = () => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      handleSendAnalytics();
    }, 8000);
  };

  // initial click inside with searchValue = ""
  useEffect(() => {
    if (searchFocused && isFirstSearch.current && searchValue === '') {
      homepageSearch({ variables: { search: searchValue } });
      isFirstSearch.current = false;
    }
  }, [searchFocused, searchValue, homepageSearch]);

  // debounce API search
  useEffect(() => {
    if (isNewSearchTerm) {
      initiateAnalyticsWithDebounce();
    }
    const timeout = setTimeout(() => {
      if (!searchFocused && isFirstSearch.current) {
        return;
      }
      homepageSearch({ variables: { search: searchValue } });
    }, 300);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchFocused, homepageSearch, isFirstSearch.current]);

  // when searchValue changes and input is focused, initiate search analytics api with debounce
  useEffect(() => {
    if (isNewSearchTerm && searchFocused) {
      initiateAnalyticsWithDebounce();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // send analytics if search input is blurred and stop debounce timer
  useEffect(() => {
    if (isNewSearchTerm && !searchFocused) {
      handleSendAnalytics();
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFocused]);

  useEffect(() => {
    if (selectedOption) {
      // send analytics when user selects an autocomplete option along with relevant data (search type and id)
      handleSendAnalytics(selectedOption.id, selectedOption.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const formatLabel = (originalLabel: string): string => {
    // designer set the maximum number of characters of search results to 41 for mobile and 75 for desktop.
    // this is done so it never goes in two lines. adding 3 dots after shortened label to indicate it's not fully displayed.
    if (isMobile) {
      return originalLabel.length > 41
        ? originalLabel.substring(0, 41) + '...'
        : originalLabel;
    } else {
      return originalLabel.length > 75
        ? originalLabel.substring(0, 75) + '...'
        : originalLabel;
    }
  };

  const loadOptions = useCallback(() => {
    const groupedOptions: SearchBarGroupProps[] = [];
    const storeNameOrganizationOptions: SearchBarOptionProps[] = [];
    const storeNameContentCreatorOptions: SearchBarOptionProps[] = [];
    const storeNameAthleteOptions: SearchBarOptionProps[] = [];
    const storeIds: string[] = [];
    const streamNameOptions: SearchBarOptionProps[] = [];
    const productOptions: SearchBarOptionProps[] = [];
    const merchOptions: SearchBarOptionProps[] = [];
    const experienceOptions: SearchBarOptionProps[] = [];
    const mediaPostOptions: SearchBarOptionProps[] = [];

    data?.stores?.entities?.forEach((item) => {
      const name = `${item?.firstName || ''} ${item?.lastName || ''}`.trim();
      const storeName = item.storeDetails?.storeName?.trim() || '';
      const role = item.role;
      const url = `/${item.slug}`;
      const id = item.id || '';

      if (role === UserRole.Organization) {
        storeNameOrganizationOptions.push({
          name,
          searchTerm: storeName,
          label: formatLabel(storeName || name),
          value: storeName + ' ' + searchValue,
          url,
          id,
          type: SearchResultType.Organization,
        });
      }

      if (role === UserRole.ContentCreator) {
        storeNameContentCreatorOptions.push({
          name,
          searchTerm: storeName,
          label: formatLabel(storeName || name),
          value: storeName + ' ' + searchValue,
          url,
          id,
          type: SearchResultType.ContentCreator,
        });
      }

      if (role === UserRole.Athlete) {
        storeNameAthleteOptions.push({
          name,
          searchTerm: storeName,
          label: formatLabel(storeName || name),
          value: storeName + ' ' + searchValue,
          url,
          id,
          type: SearchResultType.Athlete,
        });
      }

      storeIds.push(item.id);
    });

    groupedOptions.push({
      label: 'Athletes',
      options: storeNameAthleteOptions,
    });

    groupedOptions.push({
      label: 'Organizations',
      options: storeNameOrganizationOptions,
    });

    groupedOptions.push({
      label: 'Content Creators',
      options: storeNameContentCreatorOptions,
    });

    data?.streamsV2.entities?.forEach((item) => {
      const storeName = getStreamerName(item.store);
      const streamName = item.name || `${storeName}'s stream`;
      const id = item.id || '';

      streamNameOptions.push({
        name: streamName,
        searchTerm: streamName,
        label: formatLabel(streamName),
        value: streamName + ' ' + searchValue,
        url: computeOrderStreamPath(item),
        id,
        type: SearchResultType.Stream,
      });
    });

    groupedOptions.push({
      label: 'Streams',
      options: streamNameOptions,
    });

    data?.getMerchProducts.entities?.forEach((item) => {
      merchOptions.push({
        name: item.title,
        searchTerm: item.title,
        label: formatLabel(item.title),
        value: item.title + ' ' + searchValue,
        url: `${item.store?.slug}/merch/${item.slug}`,
        id: `${item.id}`,
        type: SearchResultType.Merch,
      });
    });

    groupedOptions.push({
      label: 'Merch',
      options: merchOptions,
    });

    data?.getMemorabilia.entities?.forEach((item) => {
      const type =
        item.memorabiliaProductType === MemorabiliaProductType.Memorabilia
          ? SearchResultType.Memorabilia
          : SearchResultType.Product;

      productOptions.push({
        name: item.title,
        searchTerm: item.title,
        label: formatLabel(item.title),
        value: item.title + ' ' + searchValue,
        url: `${item.store.slug}/memorabilia/${item.slug}`,
        id: item.id,
        type: type,
      });
    });

    groupedOptions.push({
      label: 'Products',
      options: productOptions,
    });

    data?.getExperiences.entities?.forEach((item) => {
      experienceOptions.push({
        name: item.title,
        searchTerm: item.title,
        label: formatLabel(item.title),
        value: item.title + ' ' + searchValue,
        url: `${item.store.slug}/experience/${item.slug}`,
        id: item.id,
        type: SearchResultType.Experience,
      });
    });

    groupedOptions.push({
      label: 'Experience',
      options: experienceOptions,
    });

    data?.getMediaPosts.entities?.forEach((item) => {
      mediaPostOptions.push({
        name: item.title,
        searchTerm: item.title,
        label: formatLabel(item.title),
        value: item.title + ' ' + searchValue,
        url: `${item.store.slug}${SOCIAL_MEDIA_POST}${item.slug}`,
        id: item.id,
        type: SearchResultType.MediaPost,
      });
    });

    groupedOptions.push({
      label: 'Media Posts',
      options: mediaPostOptions,
    });

    return groupedOptions;
  }, [data, searchValue]);

  const options = loadOptions();

  const value = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      options,
      loading,
      setSearchFocused,
      setSelectedOption,
    }),
    [
      searchValue,
      setSearchValue,
      options,
      loading,
      setSearchFocused,
      setSelectedOption,
    ]
  );

  return (
    <SearchBarContext.Provider value={value}>
      {children}
    </SearchBarContext.Provider>
  );
};

const useSearchBarContext = (): SearchBarContextType => {
  const context = useContext(SearchBarContext);

  if (!context) {
    throw new Error(
      'useSearchBarContext must be used within a SearchBarContextProvider'
    );
  }

  return context;
};

export { SearchBarContextProvider, useSearchBarContext };
